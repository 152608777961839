@font-face {
    font-family: 'silkscreennormal';
    src: url('assets/fonts/slkscr-webfont.eot');
    src: url('assets/fonts/slkscr-webfont.eot?#iefix') format('embedded-opentype'), url('assets/fonts/slkscr-webfont.woff') format('woff'),
        url('assets/fonts/slkscr-webfont.ttf') format('truetype'), url('assets/fonts/slkscr-webfont.svg#silkscreennormal') format('svg');
    font-weight: normal;
    font-style: normal;
}

#root {
    display: flex;
    flex-direction: column;
}

body {
    margin: 0;
    padding: 0;
    height: fit-content;
    min-height: fit-content;
    max-height: fit-content;
    background-color: black;
    overflow: hidden;
}

canvas,
img {
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimize-contrast;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor;
}

/* Authenticator */
.amplify-input.amplify-field-group__control {
    font-size: 0.8em;
    font-family: sans-serif;
    text-overflow: ellipsis;
}

/* Nuka Carousel */
:focus {
    outline: none;
}

/* box-shadow: [horizontal offset] [vertical offset] [blur radius] [spread radius] [color] [inset]; */
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #48f;
    }

    30% {
        border: '1px solid white';
        box-shadow: 0 0 0.25em 0.2em #48f;
    }

    100% {
        box-shadow: 0 0 0 0 #48f;
    }
}

@keyframes svgPulse {
    0% {
        filter: drop-shadow(0 0 0 #48f);
    }
    30% {
        filter: drop-shadow(0 0 0.25em #48f);
    }
    100% {
        filter: drop-shadow(0 0 0 #48f);
    }
}
